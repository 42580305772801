import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import TestInput from 'components/input/TestInput'

import { formatDateYMD, formatDateTime, getLocation, catchError, filterData, addActivity } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

const Tests = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false
  })
  // warning: 0,
  // warningContent: '',
  // loading: true,    
  // saving: false

  const [isRetest, setIsRetest] = useState(false)
  const [isFailingTest, setIsFailingTest] = useState(false)
  const [isRemoved, setIsRemoved] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      selectData('Tests').then(res => {
        setFetchedData(res.sort((a, b) => Number(b.testno) - Number(a.testno)))
        setIsModal(prevState => ({...prevState, loading: false}))
      })

    } else {

      fetch('/api/selectTests', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter,
          userLevel: props.user.userlevel
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

          setFetchedData(result.map((data, i) => {

            //let relComp = Math.round((data.drydens/data.optd)*100)

            //let passFail = relComp >= data.reqcomp ? 'P' : 'F'

            //pf for filter

            return {...data,
              pf: data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '',
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              testdate: formatDateYMD(data.testdate)
            }

          }))

          setIsModal(prevState => ({...prevState, loading: false}))

        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectTests', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

  }

  useEffect(() => {
    if (
      (props.filter.jobNumber !== '' || props.filter.user !== '' || props.filter.startDate !== '') &&
      (props.filter.jobNumber == '' || props.filter.dateName !== 'All')
    ) fetchData()
  }, [props.filter])

  const selectRow = (e) => {

    let tr = e.currentTarget        
    //let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    // } else if (fetchedData[i].syncedID === false) {
    //   alert('ID not synced. Please refresh the data.')
    } else {

      // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
      // let today = new Date()
      // let entry = new Date(fetchedData[i].entrytime)
      // let timeDiff = Math.abs(entry.getTime() - today.getTime())
      // let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

      // if (props.user.test < 2) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

      // } else if ((props.user.test === 2 || props.user.test === 3) && props.user.username !== fetchedData[i].entryby) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

      // } else if ((props.user.test === 2 || props.user.test === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

      // } else if (props.user.test === 4) {

      //   setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

      // } else {

      //   setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

      // }

      // // this is for removed, if returns undefined, then not removed
      // let removed =  fetchedData.find(test => test.testno === fetchedData[i].testno && test.suf === 'R')
      // let reTest = false

      // if (!removed && fetchedData[i].passfail === 'F') {

      //   // this is for retest, if returns undefined, then not found, so retest needed
      //   reTest = fetchedData.find(test => test.testno === fetchedData[i].testno && test.passfail === 'P')
      //   //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)

      // }

      // // used to dynamically update relComp and passFail during input
      // updatePassFail.current = {
      //   dryDens: fetchedData[i].drydens,
      //   optD: fetchedData[i].optd,
      //   reqComp: fetchedData[i].reqcomp
      // }

      // setIsValidated(prevState => ({...prevState,
      //   entryby: fetchedData[i].entryby,
      //   entrytime: fetchedData[i].entrytime,
      //   entrylat: fetchedData[i].entrylat,
      //   entrylng: fetchedData[i].entrylng,
      //   entrydevice: fetchedData[i].entrydevice,
      //   modby: fetchedData[i].modby,
      //   modtime: fetchedData[i].modtime,
      //   modlat: fetchedData[i].modlat,
      //   modlng: fetchedData[i].modlng,
      //   moddevice: fetchedData[i].moddevice,
      //   id: fetchedData[i].id,
      //   reTest: reTest === undefined ? true : false,
      //   showRemoved: removed === undefined ? true : false,
      //   removed: removed === undefined ? false : true,
      //   nukeId: fetchedData[i].nukeId,
      //   testDate: fetchedData[i].testdate,
      //   pre: fetchedData[i].pre,
      //   testNo: fetchedData[i].testno,
      //   suf: fetchedData[i].suf,
      //   testType: fetchedData[i].testtype,
      //   north: fetchedData[i].north,
      //   east: fetchedData[i].east,
      //   elevD: fetchedData[i].elevd,
      //   moistCont: fetchedData[i].moistcont,
      //   dryDens: fetchedData[i].drydens,
      //   maxId: fetchedData[i].maxId,
      //   curve: fetchedData[i].curve,
      //   optD: fetchedData[i].optd,
      //   optM: fetchedData[i].optm,
      //   reqComp: fetchedData[i].reqcomp,
      //   relComp: fetchedData[i].relcomp,
      //   passFail: fetchedData[i].passfail,        
      //   sandInitialWt: fetchedData[i].sandInitialWt, 
      //   sandFinalWt: fetchedData[i].sandFinalWt, 
      //   sandExtraWt: fetchedData[i].sandExtraWt, 
      //   sandDensity: fetchedData[i].sandDensity, 
      //   tubeWetWt: fetchedData[i].tubeWetWt, 
      //   tubeTareWt: fetchedData[i].tubeTareWt, 
      //   tubeFactor: fetchedData[i].tubeFactor, 
      //   moistWetWt: fetchedData[i].moistWetWt, 
      //   moistDryWt: fetchedData[i].moistDryWt, 
      //   moistTareWt: fetchedData[i].moistTareWt
      // }))
      openEdit(i)

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const viewFailingTest = () => setIsFailingTest(isFailingTest ? false : true)

  const viewRemoved = () => setIsRemoved(isRemoved ? false : true)

  const openAdd = () => {

    // alert('Use the preset in the Drawing tool under Plans')
    // return

    if (props.user.test < 2) {

      alert('You do not have the required permission. Contact an admin.')

    // if (isModal.warning) {
    //
    //   alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      // let testNo = fetchedData.length < 1 ? 1 : Math.max(...fetchedData.map(o => o.testno)) + 1

      // setIsValidated(prevState => ({...prevState, testNo: testNo}))
      setIsModal(prevState => ({...prevState, add: true}))

    }

  }

  const openEdit = (i) => setIsModal(prevState => ({...prevState, edit: true, i: i}))

  const closeModal = () => setIsModal(prevState => ({...prevState, add: false, edit: false, saving: false}))

  const updateFetchedData = (data) => setFetchedData(data)

  let nCount = 0
  let sCount = 0
  let dCount = 0
  let oCount = 0
  let recordCount = 0

  let listOfData = fetchedData.map((data, i) => {

    let pre = data.pre === null ? '' : data.pre
    let number = data.testno === null ? '' : data.testno
    let suf = data.suf === null ? '' : data.suf
    let testNo = `${pre} ${number} ${suf}`
    let dryDens = data.drydens === null ? '' : data.drydens
    let moistCont = data.moistcont === null ? '' : data.moistcont
    let md = `${dryDens} @ ${moistCont}`
    let optD = data.optd === null ? '' : data.optd
    let optM = data.optm === null ? '' : data.optm
    let curve = data.curve === null ? '' : data.curve
    let max = `${curve} - ${optD} @ ${optM}`
    let reqComp = data.reqcomp === null ? '' : data.reqcomp
    let elevD = data.elevd === null ? '' : data.elevd
    let passFail = data.passfail === null ? '' : data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '?'
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''

    // this is for failingTest
    let failingTest = null

    if (data.passfail !== 'P') {
      //console.log(`data.passfail: ${data.passfail}`)
      failingTest = fetchedData.find(retest => retest.testno === data.testno && retest.passfail === 'P')
      //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)
    }

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    if (
      ((isRemoved && suf === 'R') || !isRemoved) &&
      ((isFailingTest && failingTest === undefined) || !isFailingTest) &&
      filter
    ) {      

      let color = 'white'

      if (data.testtype === 'N') {
        nCount += 1
        color = 'orange'
      } else if (data.testtype === 'S') {
        sCount += 1
        color = 'dodgerblue'
      } else if (data.testtype === 'D') {
        dCount += 1
        color = 'gray'
      } else {
        oCount += 1
      }

      recordCount += 1

      return (
        <tr key={data.id.toString()} data-i={i} data-id={data.id} onClick={selectRow}>          
          {isModal.google ?
            <td>
              {data.entrylat === '' || data.entrylat === null || data.entrylng === '' || data.entrylng === null ?
              <Icon name='wrong_location' color='tomato' /> :
              <Icon name='where_to_vote' color='dodgerblue' />}
            </td> : null
          }
          <td>{data.testdate}</td>
          <td>{entryBy}</td>
          <td style={{background: color, color: 'white'}}>{testNo.trim()}</td>
          <td>{elevD}</td>
          <td>{md.trim()}</td>
          <td>{max.trim()}</td>
          <td>{data.relcomp}</td>
          <td>{reqComp}</td>          
          <td>{passFail}</td>
        </tr>
      )

    }

  })

  let content = (
    <>
      {props.filter.jobNumber === null || props.filter.jobNumber === '' ?
        null :
        <>          
          {isModal.add || isModal.edit ?
            <TestInput                
              close={closeModal} 
              fetchedData={fetchedData}
              updateFetchedData={updateFetchedData}
              selectNuke={props.selectNuke}
              isModal={isModal}
              user={props.user}
              filter={props.filter}
              planMode={props.planMode}
              component={props.component}
            /> : null
          }          
          {!isModal.loading ?
            <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

              <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

                <div style={{width: '100%'}}>

                  {props.user.test < 2 ? null : props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
                  <Icon name='warning' color={isFailingTest ? 'tomato' : 'gray'} title='View failing tests requiring re-tests' onClick={viewFailingTest} />
                  <Icon name='delete' color={isRemoved ? 'tomato' : 'gray'} title='View removed tests' onClick={viewRemoved} />
                  <Icon name='refresh' title='Refresh' onClick={fetchData} />

                </div>

                <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

                {fetchedData.length > 0 ?

                  <>

                    {props.user.userlevel === 'guest' ? <small style={{margin: 10}}>{recordCount} Total Records</small> : <small style={{margin: 10}}>Limits to past 100 entries</small>}

                    {nCount > 0 || sCount > 0 || dCount > 0 ?
                      <div style={{marginLeft: 10, marginTop: 10}}>
                        {nCount === 0 ? null : <span style={{marginRight: 10, borderRadius: 10, width: 100, padding: 10, backgroundColor: 'orange', color: 'white'}}>Nuke: {Math.round(nCount / recordCount * 100 * 10) / 10}%</span>}
                        {sCount === 0 ? null : <span style={{marginRight: 10, borderRadius: 10, width: 100, padding: 10, backgroundColor: 'dodgerblue', color: 'white'}}>Sand: {Math.round(sCount / recordCount * 100 * 10) / 10}%</span>}
                        {dCount === 0 ? null : <span style={{marginRight: 10, borderRadius: 10, width: 100, padding: 10, backgroundColor: 'gray', color: 'white'}}>Drive: {Math.round(dCount / recordCount * 100 * 10) / 10}%</span>}
                        {oCount === 0 ? null : <span style={{marginRight: 10, width: 100, padding: 10}}>?: {Math.round(oCount / recordCount * 100 * 10) / 10}%</span>}
                      </div> : null
                    }
                    
                    <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                      <table>

                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Tech</th>
                            <th>Test</th>
                            <th>E/D</th>
                            <th>D/M</th>
                            <th>Max</th>
                            <th>Rel.</th>
                            <th>Req.</th>
                            <th>P/F</th>
                          </tr>
                        </thead>

                        <tbody>
                          {listOfData}
                        </tbody>

                      </table>

                    </div>

                  </> : <p style={{margin: 10}}>No tests found.</p>

                }

              </div>

            </div> :
            <p style={{margin: 10}}>Loading...</p>

          }

        </>
      }
    </>
  )
  
  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content  

}

export default Tests
