import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import PlanDrawings from 'components/main/PlanDrawings'
import ViewLocation from 'components/utils/ViewLocation'
import Job from 'components/menu/Job'
import Alert from 'components/utils/Alert'

import { formatDateYMD, formatDateTime, getLocation, catchError, addActivity } from 'scripts/common'

import { coordsXY } from 'scripts/plans'

import { getBase64OfPlan, selectData } from 'scripts/offline'

const Plans = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    job: false,
    draw: false,
    index: false,
    loading: true,
    alert: false,
    alertContent: ''
  })

  const [isLoading, setIsLoading] = useState(true)

  const countErrorLoadPDF = useRef(0)

  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  //=== these values are here to maintain the filters through zoom. if in plansdraw, they get wiped out

  const [searchLayer, setSearchLayer] = useState('')
  const [distinctPresets, setDistinctPresets] = useState([])
  
  const updateSearchLayer = (value) => setSearchLayer(value)
  const updateDistinctPresets = (value) => setDistinctPresets(value)

  //===================================================================================================

  const canvasPlan = useRef(null)
  const canvasDraw = useRef(null)

  const plansMode = useRef('plansdraw')
  const planIndex = useRef(null)

  const planExtents = useRef(null) // updatePlanExtents depends on isValidated for coordsXY, but isValidated isnt ready in time... so ref carries data

  const pdf = useRef({
    id: null,
    zoomFactor: 0.5,
    doc: null,
    scale: 1,
    zoomScale: 1 // tracks scale factor for wheel/pinch zoom. more explanation to come as to why it is separate...
  })

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    gradeId: '',
    type: '',
    description: '',
    sht: '',
    x1: null,
    x2: null,
    y1: null,
    y2: null,
    n1: null,
    n2: null,
    e1: null,
    e2: null,
    angle: null,
    m0: null,
    m1: null,
    m2: null,
    m3: null,
    m4: null,
    m5: null,
    maxN: '',
    maxE: '',
    minN: '',
    minE: '',
    base64: ''
  })  

  const fetchData = () => {    

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      selectData('Plans').then(res => {
        setFetchedData(res)
        setIsModal(prevState => ({...prevState, loading: false}))
      })

    // } else if (props.user.localData) {
    //
    //   selectData('Plans', props.filter)
    //   .then(res => {
    //     setFetchedData(res)
    //     setIsModal(prevState => ({...prevState, loading: false}))
    //   })
    //   .catch(err => fetch())

    } else {

      fetch('/api/selectPlans', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

          setFetchedData(result)

          setIsModal(prevState => ({...prevState, loading: false}))
        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectPlans', JSON.stringify(error), props.user.username, props.user.device)

        }
      )      

    }

  }

  const fetchPresets = (data) => {

    fetch('/api/distinctPresets', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        planInfo: data
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log(`distinctPresets: ${JSON.stringify(result)}`)

        //let res = result

        if (result.find(o => o.id === null)) {

          result.shift()

          //console.log(`res: ${result}`)

          result.unshift(
            {
              id: 0,
              presetName: 'Custom',              
              status: true
            }
          )


        }        

        result.push(          
          {
            id: -1,
            presetName: 'Issue',
            color: '#FF6347FF', //tomato
            status: true
          },
          {
            id: -2,
            presetName: 'Tests',
            color: '#000000FF',
            status: true
          },
          {
            id: -3,
            presetName: 'Lab',
            color: '#964B00FF',
            status: true
          },
          {
            id: -4,
            presetName: 'Presat',
            color: '#0000FFFF',
            status: true
          },
          {
            id: -5,
            presetName: 'Temporary',
            color: '#FFD700FF',
            status: true
          },
          {
            id: -6,
            presetName: 'Hide All',
            color: '#000000FF',
            status: false
          }
        )
        
        // result.map(preset => ({...preset, status: preset.presetName === 'Hide All' ? false : true}))

        updateDistinctPresets(result)

        openDraw(data.id, data.modtime, data.base64)

      },
      (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'distinctPresets', JSON.stringify(error), props.user.username, props.user.device)

    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter.jobNumber, props.filter.gradeId])

  // const validate = (event) => {
  //   let name = event.target.getAttribute('name')
  //   let state = event.target.reportValidity()
  //   let type = event.target.type
  //   let value = type === 'checkbox' ? event.target.checked : event.target.value

  //   setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  // }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        jobNumber: fetchedData[i].jobnumber,
        gradeId: fetchedData[i].gradeid,
        type: fetchedData[i].type,
        description: fetchedData[i].description,
        sht: fetchedData[i].sht,
        x1: fetchedData[i].x1,
        x2: fetchedData[i].x2,
        y1: fetchedData[i].y1,
        y2: fetchedData[i].y2,
        n1: fetchedData[i].n1,
        n2: fetchedData[i].n2,
        e1: fetchedData[i].e1,
        e2: fetchedData[i].e2,
        angle: fetchedData[i].angle,
        m0: fetchedData[i].matrix0,
        m1: fetchedData[i].matrix1,
        m2: fetchedData[i].matrix2,
        m3: fetchedData[i].matrix3,
        m4: fetchedData[i].matrix4,
        m5: fetchedData[i].matrix5,
        maxN: fetchedData[i].maxN,
        maxE: fetchedData[i].maxE,
        minN: fetchedData[i].minN,
        minE: fetchedData[i].minE,
        base64: fetchedData[i].base64
      }))

      // explanation above

      planExtents.current = {
        id: fetchedData[i].id,
        angle: fetchedData[i].angle,
        m0: fetchedData[i].matrix0,
        m1: fetchedData[i].matrix1,
        m2: fetchedData[i].matrix2,
        m3: fetchedData[i].matrix3,
        m4: fetchedData[i].matrix4,
        m5: fetchedData[i].matrix5,
        maxN: fetchedData[i].maxN,
        maxE: fetchedData[i].maxE,
        minN: fetchedData[i].minN,
        minE: fetchedData[i].minE
      }

      fetchPresets(fetchedData[i])

      // moved inside fetchpresets
      //openDraw(fetchedData[i].id, fetchedData[i].modtime, fetchedData[i].base64)

    }

  }

  const updatePlanExtents = (id, maxN, maxE, minN, minE) => {

    getLocation(function(latlng){

      fetch('/api/updatePlanExtents', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: latlng.lat,
          lng: latlng.lng,
          device: props.user.device,
          id: id,
          maxN: maxN,
          maxE: maxE,
          minN: minN,
          minE: minE
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))
        },
        (error) => {
          alert('Error: could not update plan extents. This may prevent the drawings from loading. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'updatePlanExtents', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    })

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const selectJob = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setIsValidated(prevState => ({...prevState,jobNumber: td[2].textContent}))
    closeJob()

  }

  const changedData = () => isChanged.current = true

  const savedData = () => isChanged.current = false

  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))

  const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  const openAlert = (content) => setIsModal(prevState => ({...prevState, alert: true, alertContent: content}))

  const closeAlert = () => setIsModal(prevState => ({...prevState, alert: false}))

  const loadPDF = (url, urlAlt) => { // urlAlt allows reload attempt with actual url and not base64

    //Loads PDF into canvasDraw
    pdfjsLib.getDocument(url).promise.then(function(doc) {

        pdf.current.id = planExtents.current.id
        pdf.current.doc = doc

        // Initial/first page rendering
        renderPage(doc, pdf.current.scale);

    })
    .then(result => {

      setIsLoading(false)

    })
    .catch(error => {

      catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'loadPDF', JSON.stringify(error), props.user.username, props.user.device)

      if (countErrorLoadPDF.current < 4) {

        openAlert(`Failed to load. Trying again. Attempt ${countErrorLoadPDF.current} of 3`)

        let data = urlAlt === undefined ? url : urlAlt
        loadPDF(data)
        countErrorLoadPDF.current += 1

      } else {
        alert(`Could not load. Try 'Reload Plan' from the left menu.`)
        countErrorLoadPDF.current = 0
      }


    })

  }

  const reloadPDF = () => {

    setIsLoading(true)
    let url = `/plans/plan${isValidated.id}.pdf`
    loadPDF(url)

  }

  const openDraw = (id, modtime, base64) => {

    addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'select', `plan${id}`, props.user.username)

    setIsModal(prevState => ({...prevState, draw: true}))

    let url = '/plans/plan' + id + '.pdf';
    //let url = 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf'
    //let url ='/plans/helloworld.pdf'

    // ######################################################################################################

    // TESTING SAVING PDF AS base64 and saved in localStorage

    // ######################################################################################################

    // loadPDF move outside function

    // If you are loading file from a remote server, be sure to configure “Access-Control-Allow-Origin”
    // For example, the following image can be loaded from anywhere

    // Initialize the XMLHttpRequest and wait until file is loaded

    const cachePlan = (url, id, modtime) => {

      getBase64OfPlan(url, id, modtime)
      .then(result => {
        //console.log(result)

        let data = {data: atob(result)}

        loadPDF(data, url)
      })
      .catch(error => {
        console.error('Error:', error.statusText)

        loadPDF(url)
      })

    }

    // is the plan in localStorage ?

    let localPlan = JSON.parse(localStorage.getItem('localPlan'))

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      let data = {data: atob(base64)}

      loadPDF(data)

    } else if (localStorage.getItem('localPlan') === null) {
      //console.log('remotePlan')
      cachePlan(url, id, modtime)

    } else if (localPlan.id === id && localPlan.modtime === modtime) {
      //console.log('localPlan')
      let data = {data: atob(localPlan.base64)}

      loadPDF(data)

    } else {
      //console.log('remotePlan')
      cachePlan(url)

    }

    // ######################################################################################################

    // //Loads PDF into canvasDraw
    // pdfjsLib.getDocument({ data: pdfData, }).promise.then(function(doc) {
    //
    //     pdf.current.id = id
    //     pdf.current.doc = doc
    //
    //     // Initial/first page rendering
    //     renderPage(doc, pdf.current.scale);
    //
    // });

  }

  // const openPlan = () => openDraw(isValidated.id, isValidated.modtime, isValidated.base64)
  //
  // const openAdd = () => {
  //
  //   if (props.user.plan < 2) {
  //     alert('You do not have the required permission. Contact an admin.')
  //   } else {
  //     setIsModal(prevState => ({...prevState, add: true}))
  //   }
  //
  // }

  //const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openIndex = () => {

    let index = fetchedData.find(data => data.type === 'Index')

    if (index === undefined) {
      alert('An index map has not been attached. Contact a manager.')
    } else {

      planIndex.current = '/plans/plan' + index.id + '.png'

      setIsModal(prevState => ({...prevState, index: true}))

    }

  }

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, draw: false, add: false, edit: false}))
        //clearIsValidated()
        setIsLoading(true)
      }
    } else {
      setIsModal(prevState => ({...prevState, draw: false, add: false, edit: false}))
      //clearIsValidated()
      setIsLoading(true)
    }

  }

  const closeIndex = () => setIsModal(prevState => ({...prevState, index: false}))

  let listOfData = fetchedData.map((data, i) => {

    if (data.type === 'Plan') {

      let sht = data.sht === null ? '' : data.sht
      let description = data.description === null ? '' : data.description
      let entryby = data.entryby === null ? '' : data.entryby
      let entrytime = data.entrytime === null || data.entrytime === '' ? '' : formatDateYMD(data.entrytime)
      let modby = data.modby === null ? '' : data.modby
      let modtime = data.modtime === null || data.modtime === '' ? '' : formatDateYMD(data.modtime)

      let offline = false
      if (localStorage.getItem('localPlan') !== null) {

        let localPlan = JSON.parse(localStorage.getItem('localPlan'))
        offline = localPlan.id === data.id ? true : false

      }

      if (
        searchValue === '' ||
        sht.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        modby.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        modtime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      ) {
        return (
          <tr key={data.id.toString()} onClick={selectRow}>
              <td style={{display: 'none'}}>{i}</td>
              <td>{sht}</td>
              <td>{description}</td>
              <td>{modby === '' ? entryby : modby}</td>
              <td>{modtime === '' ? entrytime : entrytime}</td>
              <td>{offline ? <Icon name='offline_pin' /> : ''}</td>
          </tr>
        )
      }

    }

  })

  //========================================
  //==  Mapping
  //========================================

  // The link below explains how to use npm
  // https://github.com/mozilla/pdf.js/wiki/Setup-pdf.js-in-a-website

  // If absolute URL from the remote server is provided, configure the CORS
  // header on that server.

  // Loaded via <script> tag, create shortcut to access pdf.current.js exports.
  const pdfjsLib = window['pdfjs-dist/build/pdf'];
  //const pdfjsLib = require('pdfjs-dist/build/pdf.js')
  //const pdfjsLib = require('pdfjs-dist')

  // The workerSrc property shall be specified.
  pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf.worker.js';
  //pdfjsLib.GlobalWorkerOptions.workerSrc = './node_modules/pdfjs-dist/build/pdf.worker.entry.js'

  const renderPage = (doc, scale) => {

    doc.getPage(1).then(function(page) {

      let viewport = page.getViewport({scale: scale})

      canvasDraw.current.height = viewport.height
      canvasDraw.current.width = viewport.width

      canvasPlan.current.height = viewport.height
      canvasPlan.current.width = viewport.width

      // Plans draw now filters drawings by PDF extents. This makes PDFs backwards compatible ( < 3/4/2022)

      if (
        planExtents.current.maxN === '' || planExtents.current.maxE === '' || planExtents.current.minN === '' || planExtents.current.minE === '' ||
        Number.isNaN(planExtents.current.maxN) || Number.isNaN(planExtents.current.maxE) || Number.isNaN(planExtents.current.minN) || Number.isNaN(planExtents.current.minE)
      ) {

        let coords = coordsXY(canvasDraw.current.width, canvasDraw.current.height, planExtents.current, 1, props.planMode)

        let maxN = coords.n
        let maxE = coords.e

        coords = coordsXY(0, 0, planExtents.current, 1, props.planMode)

        let minN = coords.n
        let minE = coords.e

        setIsValidated(prevState => ({...prevState,
          maxN: maxN,
          maxE: maxE,
          minN: minN,
          minE: minE
        }))

        planExtents.current.maxN = maxN
        planExtents.current.maxN = maxE
        planExtents.current.maxN = minN
        planExtents.current.maxN = minE

        updatePlanExtents(planExtents.current.id, maxN, maxE, minN, minE)

      }

      // Render PDF page into canvas context
      let renderContext = {
        canvasContext: canvasPlan.current.getContext('2d'),
        viewport: viewport
      }

      page.render(renderContext)

    })
    //.then(res => setIsLoading(false))
    .then(
      (result) => {

        setIsLoading(false)

      },
      (error) => {

        alert('Error: could not load document page. Contact and admin.')

        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'renderPage', JSON.stringify(error), props.user.username, props.user.device)

      }
    )

  }

  const zoomIn = () => {

    let scale = pdf.current.scale / pdf.current.zoomFactor;

    pdf.current.scale = scale

    setIsLoading(true)

    renderPage(pdf.current.doc, scale);

  }

  const zoomOut = () => {

    // if (pdf.current.scale <= pdf.current.zoomFactor) { // zoomFactor currently set to 0.5 in vars above per Daves request

    //   alert('Maximum zoom out reached.');

    // } else {

      let scale = pdf.current.scale * pdf.current.zoomFactor;

      pdf.current.scale = scale

      setIsLoading(true)

      renderPage(pdf.current.doc, scale);

  //   }

  }

  const updateScale = (scale) => pdf.current.zoomScale = scale

  let content = (

    <>
      {isModal.index ? <Modal content={<img style={{height: '100%', width: '100%'}} src={planIndex.current} />} closeModal={closeIndex} /> : null}
      {isModal.job ? <Job user={props.user} selectJob={selectJob} closeModal={closeJob} /> : null}
      {isModal.draw ?
        <PlanDrawings
          user={props.user}
          closeModal={closeModal}
          changedData={changedData}
          savedData={savedData}
          canvasPlan={canvasPlan}
          canvasDraw={canvasDraw}
          mode={props.planMode}
          planInfo={isValidated}
          pdf={pdf.current}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          updateScale={updateScale}
          filter={props.filter}
          selectLocate={props.selectLocate}
          selectNuke={props.selectNuke} // here to pass on to <Tests>
          reloadPDF={reloadPDF}
          data={props.data} // passes data from lab and test for display
          closePlan={props.closePlan} // used to close plan if opened from lab, tests, etc
          isLoading={isLoading}
          component='Drawings' // for addActivity
          distinctPresets={distinctPresets}
          updateDistinctPresets={updateDistinctPresets}
          searchLayer={searchLayer}
          updateSearchLayer={updateSearchLayer}
        /> : null
      }
      {isModal.alert ? <Alert content={isModal.alertContent} close={closeAlert} /> : null}
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

            <div style={{width: '100%'}}>


              <Icon name='refresh' title='Refresh' onClick={fetchData} />

            </div>

            <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <div style={{flex: '1', overflow: 'auto'}}>

                <table>

                  <thead>
                    <tr>
                      <th>Sht</th>
                      <th>Descrip.</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {listOfData}
                  </tbody>

                </table>

              </div> :
              <p style={{margin: 10}}>No plans found.</p>

            }

          </div>

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }

    </>

  )

  return props.modal ? <Modal content={content} closeModal={props.closePlan} zIndex={props.zIndex} /> : (props.filter.jobNumber !== null && props.filter.jobNumber !== '') || props.component === 'Manage' ? content : null // this allows plans to be used as a Modal inside Test, Lab, etc

}

export default Plans
