import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Plans from 'components/main/Plans'

import IssueInput from 'components/input/IssueInput'

import { formatDateYMD, formatDateTime, getLocation, catchError, filterData, addActivity, replaceStr } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

const Issue = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [fetchedAction, setFetchedAction] = useState([])

  const listOfActions = useRef(null)

  // id tracks the selected record for action

  const [isModal, setIsModal] = useState({
    loading: true,
    add: false,
    edit: false,
    unresolved: false,
    action: false,
    addAction: false,
    editAction: false
  })

  const [searchValue, setSearchValue] = useState('')  

  // const [isValidated, setIsValidated] = useState({
  //   entryby: '',
  //   entrytime: null,
  //   entrylat: '',
  //   entrylng: '',
  //   entrydevice: '',
  //   modby: '',
  //   modtime: null,
  //   modlat: '',
  //   modlng: '',
  //   moddevice: '',
  //   id: null,
  //   issueDate: '',
  //   issueNo: null,
  //   description: '',
  //   resolved: 0,
  //   other: '',    
  //   n: '',
  //   e: ''
  // })

  // const clearIsValidated = () => setIsValidated({
  //   entryby: '',
  //   entrytime: null,
  //   entrylat: '',
  //   entrylng: '',
  //   entrydevice: '',
  //   modby: '',
  //   modtime: null,
  //   modlat: '',
  //   modlng: '',
  //   moddevice: '',
  //   id: null,
  //   issueDate: '',
  //   issueNo: null,
  //   description: '',
  //   resolved: 0,
  //   other: '',  
  //   n: '',
  //   e: ''
  // })

  // const [isValidatedAction, setIsValidatedAction] = useState({
  //   entryby: '',
  //   entrytime: null,
  //   entrylat: '',
  //   entrylng: '',
  //   entrydevice: '',
  //   modby: '',
  //   modtime: null,
  //   modlat: '',
  //   modlng: '',
  //   moddevice: '',
  //   id: null,
  //   issueId: null,
  //   actionDate: '',
  //   description: ''
  // })

  // const clearIsValidatedAction = () => setIsValidatedAction({
  //   entryby: '',
  //   entrytime: null,
  //   entrylat: '',
  //   entrylng: '',
  //   entrydevice: '',
  //   modby: '',
  //   modtime: null,
  //   modlat: '',
  //   modlng: '',
  //   moddevice: '',
  //   id: null,
  //   issueId: null,
  //   actionDate: '',
  //   description: ''
  // })

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      // selectData('Tests').then(res => {
      //   setFetchedData(res.sort((a, b) => Number(b.testno) - Number(a.testno)))
      //   setIsModal(prevState => ({...prevState, loading: false}))
      // })

    } else {

      fetch('/api/selectIssues', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

          setFetchedData(result.map((data, i) => {

            return {...data,              
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              issueDate: formatDateYMD(data.issueDate)
            }

          }))          

          //setIsModal(prevState => ({...prevState, loading: false}))

        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectIssues', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

      fetch('/api/selectIssueActions', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          setFetchedAction(result.map((data, i) => {

            return {...data,              
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              actionDate: formatDateYMD(data.actionDate)
            }

          }))

          setIsModal(prevState => ({...prevState, loading: false}))

        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectIssueActions', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

  }  

  useEffect(() => {
    if (
      (props.filter.jobNumber !== '' || props.filter.user !== '' || props.filter.startDate !== '') &&
      (props.filter.jobNumber == '' || props.filter.dateName !== 'All')
    ) fetchData()
  }, [props.filter])

  const selectRow = (e) => {

    let tr = e.currentTarget
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')
    let table = tr.getAttribute('data-table')

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      if (table === 'issue') {

        // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
        // let today = new Date()
        // let entry = new Date(fetchedData[i].entrytime)
        // let timeDiff = Math.abs(entry.getTime() - today.getTime())
        // let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

        // if (props.user.issue < 2) {

        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

        // } else if ((props.user.issue === 2 || props.user.issue === 3) && props.user.username !== fetchedData[i].entryby) {

        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

        // } else if ((props.user.issue === 2 || props.user.issue === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

        // } else if (props.user.issue === 4) {

        //   setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

        // } else {

        //   setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

        // }

        // setIsValidated(prevState => ({...prevState,
        //   entryby: fetchedData[i].entryby,
        //   entrytime: fetchedData[i].entrytime,
        //   entrylat: fetchedData[i].entrylat,
        //   entrylng: fetchedData[i].entrylng,
        //   entrydevice: fetchedData[i].entrydevice,
        //   modby: fetchedData[i].modby,
        //   modtime: fetchedData[i].modtime,
        //   modlat: fetchedData[i].modlat,
        //   modlng: fetchedData[i].modlng,
        //   moddevice: fetchedData[i].moddevice,
        //   id: fetchedData[i].id,
        //   issueDate: fetchedData[i].issueDate,
        //   issueNo: fetchedData[i].issueNo,
        //   description: fetchedData[i].description,
        //   resolved: fetchedData[i].resolved,
        //   other: fetchedData[i].other,
        //   n: fetchedData[i].n,
        //   e: fetchedData[i].e
        // }))
        openEdit(i)

      } else if (table === 'listOfActions') {

        // setIsValidated(prevState => ({...prevState,          
        //   id: fetchedData[i].id
        // }))

        openAction(i, fetchedData[i].id)
  
      } else if (table === 'action') {         

        // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
        // let today = new Date()
        // let entry = new Date(fetchedAction[i].entrytime)
        // let timeDiff = Math.abs(entry.getTime() - today.getTime())
        // let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

        // if (props.user.issue < 2) {

        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

        // } else if ((props.user.issue === 2 || props.user.issue === 3) && props.user.username !== fetchedAction[i].entryby) {

        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedAction[i].entryby} is the owner.`}))

        // } else if ((props.user.issue === 2 || props.user.issue === 3) && props.user.username === fetchedAction[i].entryby && diffDays > 1) {

        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

        // } else if (props.user.issue === 4) {

        //   setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

        // } else {

        //   setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

        // }

        // setIsValidatedAction(prevState => ({...prevState,
        //   entryby: fetchedAction[i].entryby,
        //   entrytime: fetchedAction[i].entrytime,
        //   entrylat: fetchedAction[i].entrylat,
        //   entrylng: fetchedAction[i].entrylng,
        //   entrydevice: fetchedAction[i].entrydevice,
        //   modby: fetchedAction[i].modby,
        //   modtime: fetchedAction[i].modtime,
        //   modlat: fetchedAction[i].modlat,
        //   modlng: fetchedAction[i].modlng,
        //   moddevice: fetchedAction[i].moddevice,
        //   id: fetchedAction[i].id,
        //   issueId: fetchedAction[i].issueId,
        //   actionDate: fetchedAction[i].actionDate,          
        //   description: fetchedAction[i].description          
        // }))

        let j = tr.getAttribute('data-j')
        //let id = tr.getAttribute('data-id')

        // i: issues
        // j: actions
        // id: issueId for action

        openActionEdit(j)

      }

    }

  }

  // const changedData = () => isChanged.current = true
  
  // const changedDataAction = () => isChangedAction.current = true

  // const addData = () => {

  //   const addToServer = () => {

  //     setIsModal(prevState => ({...prevState, saving: true}))

  //     getLocation(function(latlng){

  //       fetch('/api/addIssue', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: props.user.device,
  //           jobnumber: props.filter.jobNumber,
  //           gradeid: props.filter.gradeId,            
  //           issueDate: isValidated.issueDate,
  //           issueNo: isValidated.issueNo,
  //           description: replaceStr(isValidated.description),
  //           resolved: isValidated.resolved,
  //           other: replaceStr(isValidated.other),
  //           n: isValidated.n,
  //           e: isValidated.e
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))            

  //           addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add issue', `issue id: ${isValidated.id}: ${isValidated.description}`, props.user.username)

  //           fetchData() // i need the id if edited
  //           isChanged.current = false
  //           closeModal()

  //         },
  //         (error) => {
  //           setIsModal(prevState => ({...prevState, saving: false}))
  //           alert('Error: could not add. Contact and admin.')
  //           catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addIssue', JSON.stringify(error), props.user.username, props.user.device)

  //         }
  //       )

  //     })

  //   }

  //   if (props.user.issue < 2) {
  //     alert('You do not have the required permission. Contact an admin.')
  //   } else if (isValidated.issueDate === null || isValidated.issueDate ==='') {
  //       alert("Please select an issue date.")
  //   } else if (isValidated.description === null || isValidated.description ==='') {
  //     alert("Please select a description.")
  //   } else if (isValidated.n === null || isValidated.n ==='') {
  //     alert("Please select a North.");
  //   } else  if (isValidated.e === null || isValidated.e ==='') {
  //     alert("Please select an East.");
  //   } else {

  //     // include optd and optm???

  //     if (props.user.bufferData) {

  //       // let id = Math.max(...fetchedData.map(o => o.id)) + 1

  //       // addPending({
  //       //   actionId: 1,
  //       //   action: 'addTest',
  //       //   table: 'Tests',
  //       //   jobnumber: props.filter.jobNumber,
  //       //   gradeid: props.filter.gradeId,
  //       //   entryby: props.user.username,
  //       //   entrytime: formatDateTime(new Date()),
  //       //   entrylat: '',
  //       //   entrylng: '',
  //       //   entrydevice: props.user.device,
  //       //   modby: '',
  //       //   modtime: null,
  //       //   modlat: '',
  //       //   modlng: '',
  //       //   moddevice: '',
  //       //   by: props.user.username,
  //       //   time: formatDateTime(new Date()),
  //       //   lat: '',
  //       //   lng: '',
  //       //   device: props.user.device,
  //       //   id: id,
  //       //   nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
  //       //   testdate: isValidated.testDate,
  //       //   pre: isValidated.pre,
  //       //   testno: isValidated.testNo,
  //       //   suf: isValidated.suf,
  //       //   testtype: isValidated.testType,
  //       //   north: isValidated.north,
  //       //   east: isValidated.east,
  //       //   elevd: isValidated.elevD,
  //       //   moistcont: isValidated.moistCont,
  //       //   drydens: isValidated.dryDens,
  //       //   maxId: isValidated.maxId,
  //       //   curve: isValidated.curve,
  //       //   optd: isValidated.optD,
  //       //   optm: isValidated.optM,
  //       //   reqcomp: isValidated.reqComp,
  //       //   relcomp: isValidated.relComp,
  //       //   passfail: isValidated.passFail,
  //       //   removed: isValidated.removed,
  //       //   showRemoved: isValidated.showRemoved
  //       // })
  //       // .then(
  //       //   (result) => {
  //       //     //console.log('result: ' + JSON.stringify(result))

  //       //     setFetchedData(prevState =>
  //       //       [
  //       //         {
  //       //           syncedID: false,
  //       //           entryby: props.user.username,
  //       //           entrytime: formatDateTime(new Date()),
  //       //           entrylat: '',
  //       //           entrylng: '',
  //       //           entrydevice: props.user.device,
  //       //           id: id,
  //       //           nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
  //       //           testdate: isValidated.testDate,
  //       //           testtype: isValidated.testType,
  //       //           pre: isValidated.pre,
  //       //           testno: isValidated.testNo,
  //       //           suf: isValidated.suf,
  //       //           testtype: isValidated.testType,
  //       //           north: isValidated.north,
  //       //           east: isValidated.east,
  //       //           elevd: isValidated.elevD,
  //       //           moistcont: isValidated.moistCont,
  //       //           drydens: isValidated.dryDens,
  //       //           maxId: isValidated.maxId,
  //       //           curve: isValidated.curve,
  //       //           optd: isValidated.optD,
  //       //           optm: isValidated.optM,
  //       //           reqcomp: isValidated.reqComp,
  //       //           relcomp: isValidated.relComp,
  //       //           passfail: isValidated.passFail,
  //       //           removed: isValidated.removed
  //       //         },
  //       //         ...prevState
  //       //       ]
  //       //     )

  //       //     isChanged.current = false
  //       //     closeModal()

  //       //   },
  //       //   (error) => {

  //       //     addToServer()
  //       //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addTestOffline', JSON.stringify(error), props.user.username, props.user.device)
  //       //   }
  //       // )

  //     } else {

  //       addToServer()

  //     }

  //   }

  // }

  // const editData = () => {

  //   const addToServer = () => {

  //     setIsModal(prevState => ({...prevState, saving: true}))

  //     getLocation(function(latlng){

  //       fetch('/api/editIssue', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           id: isValidated.id,
  //           device: props.user.device,
  //           issueDate: isValidated.issueDate,
  //           issueNo: isValidated.issueNo,
  //           description: replaceStr(isValidated.description),
  //           resolved: isValidated.resolved,
  //           other: replaceStr(isValidated.other),
  //           n: isValidated.n,
  //           e: isValidated.e
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))

  //           setFetchedData(fetchedData.map(data =>
  //             data.id === isValidated.id ?
  //             {...data,
  //               modby: props.user.username,
  //               modtime: formatDateTime(new Date()),
  //               modlat: latlng.lat,
  //               modlng: latlng.lng,
  //               moddevice: props.user.device,
  //               issueDate: isValidated.issueDate,
  //               issueNo: isValidated.issueNo,
  //               description: isValidated.description,
  //               resolved: isValidated.resolved,
  //               other: isValidated.other,
  //               n: isValidated.n,
  //               e: isValidated.e
  //             } :
  //             data
  //           ))            

  //           addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit issue', `issue id: ${isValidated.id}: ${isValidated.description}`, props.user.username)

  //           isChanged.current = false
  //           closeModal()
  //           //alert('Updated')

  //         },
  //         (error) => {
  //           setIsModal(prevState => ({...prevState, saving: false}))
  //           alert('Error: could not edit. Contact and admin.')
  //           catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editIssue', JSON.stringify(error), props.user.username, props.user.device)

  //         }
  //       )

  //     })

  //   }

  //   // if (props.user.issue < 2) {
  //   //   alert('You do not have the required permission. Contact an admin.')
  //   if (isModal.warning > 1) {
  //     alert('You do not have the required permission. Contact an admin.')
  //   } else if (isChanged.current === false) {
  //     alert('Nothing has been changed.')      
  //   } else if (isValidated.issueDate === null || isValidated.issueDate ==='') {
  //       alert("Please select an issue date.")
  //   } else if (isValidated.description === null || isValidated.description ==='') {
  //     alert("Please select a description.")
  //   } else if (isValidated.n === null || isValidated.n ==='') {
  //     alert("Please select a North.");
  //   } else  if (isValidated.e === null || isValidated.e ==='') {
  //     alert("Please select an East.");    
  //   } else {

  //     if (props.user.bufferData) {

  //       // addPending({
  //       //   actionId: 2,
  //       //   action: 'editTest',
  //       //   table: 'Tests',
  //       //   jobnumber: props.filter.jobNumber,
  //       //   gradeid: props.filter.gradeId,
  //       //   entryby: props.user.username,
  //       //   entrytime: formatDateTime(new Date()),
  //       //   entrylat: '',
  //       //   entrylng: '',
  //       //   entrydevice: props.user.device,
  //       //   modby: '',
  //       //   modtime: null,
  //       //   modlat: '',
  //       //   modlng: '',
  //       //   moddevice: '',
  //       //   by: props.user.username,
  //       //   time: formatDateTime(new Date()),
  //       //   lat: '',
  //       //   lng: '',
  //       //   device: props.user.device,
  //       //   id: isValidated.id,
  //       //   nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
  //       //   testdate: isValidated.testDate,
  //       //   pre: isValidated.pre,
  //       //   testno: isValidated.testNo,
  //       //   suf: isValidated.suf,
  //       //   testtype: isValidated.testType,
  //       //   north: isValidated.north,
  //       //   east: isValidated.east,
  //       //   elevd: isValidated.elevD,
  //       //   moistcont: isValidated.moistCont,
  //       //   drydens: isValidated.dryDens,
  //       //   maxId: isValidated.maxId,
  //       //   curve: isValidated.curve,
  //       //   optd: isValidated.optD,
  //       //   optm: isValidated.optM,
  //       //   reqcomp: isValidated.reqComp,
  //       //   relcomp: isValidated.relComp,
  //       //   passfail: isValidated.passFail,
  //       //   removed: isValidated.removed,
  //       //   showRemoved: isValidated.showRemoved
  //       // })
  //       // .then(
  //       //   (result) => {
  //       //     //console.log('result: ' + JSON.stringify(result))

  //       //     setFetchedData(fetchedData.map(data =>
  //       //       data.id === isValidated.id ?
  //       //       {...data,
  //       //         modby: props.user.username,
  //       //         modtime: formatDateTime(new Date()),
  //       //         modlat: '',
  //       //         modlng: '',
  //       //         moddevice: props.user.device,
  //       //         testdate: isValidated.testDate,
  //       //         testtype: isValidated.testType,
  //       //         pre: isValidated.pre,
  //       //         testno: isValidated.testNo,
  //       //         suf: isValidated.suf,
  //       //         testtype: isValidated.testType,
  //       //         north: isValidated.north,
  //       //         east: isValidated.east,
  //       //         elevd: isValidated.elevD,
  //       //         moistcont: isValidated.moistCont,
  //       //         drydens: isValidated.dryDens,
  //       //         maxId: isValidated.maxId,
  //       //         curve: isValidated.curve,
  //       //         optd: isValidated.optD,
  //       //         optm: isValidated.optM,
  //       //         reqcomp: isValidated.reqComp,
  //       //         relcomp: isValidated.relComp,
  //       //         passfail: isValidated.passFail,
  //       //         removed: isValidated.removed
  //       //       } :
  //       //       data
  //       //     ))

  //       //     isChanged.current = false
  //       //     closeModal()

  //       //   },
  //       //   (error) => {

  //       //     addToServer()
  //       //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editTestOffline', JSON.stringify(error), props.user.username, props.user.device)
  //       //   }
  //       // )

  //     } else {

  //       addToServer()

  //     }

  //   }

  // }

  // const deleteData = () => {

  //   const addToServer = () => {

  //     setIsModal(prevState => ({...prevState, saving: true}))

  //     fetch('/api/deleteIssue', {
  //       method: 'post',
  //       headers: {
  //         'Accept': 'application/json, text/plain, */*',
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         id: isValidated.id
  //       })
  //     })
  //     .then(res=>res.json())
  //     .then(
  //       (result) => {
  //         //console.log('result: ' + JSON.stringify(result))          

  //         addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete issue', `issue id: ${isValidated.id}: ${isValidated.description}`, props.user.username)

  //         //fetchData()
  //         setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
  //         isChanged.current = false
  //         closeModal()

  //       },
  //       (error) => {
  //         setIsModal(prevState => ({...prevState, saving: false}))
  //         alert('Error: could not delete. Contact and admin.')
  //         catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteIssue', JSON.stringify(error), props.user.username, props.user.device)

  //       }
  //     )

  //   }

  //   if (isModal.warning > 0) {
  //       alert('You do not have the required permission. Contact an admin.')
  //   } else {

  //     if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

  //       if (props.user.bufferData) {

  //         // addPending({
  //         //   actionId: 3,
  //         //   action: 'deleteTest',
  //         //   table: 'Tests',
  //         //   id: isValidated.id
  //         // })
  //         // .then(
  //         //   (result) => {
  //         //     //console.log('result: ' + JSON.stringify(result))

  //         //     //fetchData()
  //         //     setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
  //         //     isChanged.current = false
  //         //     closeModal()
  //         //     //alert('Deleted.')

  //         //   },
  //         //   (error) => {

  //         //     addToServer()
  //         //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteTestOffline', JSON.stringify(error), props.user.username, props.user.device)
  //         //   }
  //         // )

  //       } else {

  //         addToServer()

  //       }

  //     }

  //   }

  // }
  
  // const addAction = () => {

  //   const addToServer = () => {

  //     setIsModal(prevState => ({...prevState, saving: true}))

  //     getLocation(function(latlng){

  //       fetch('/api/addIssueAction', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: props.user.device,                        
  //           actionDate: isValidatedAction.actionDate,
  //           issueId: isValidated.id,
  //           description: replaceStr(isValidatedAction.description)
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))            

  //           addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add issue action', `issue id: ${isValidated.id}: ${isValidatedAction.description}`, props.user.username)

  //           fetchData() // i need the id if edited
  //           isChangedAction.current = false
  //           closeAction()

  //         },
  //         (error) => {
  //           setIsModal(prevState => ({...prevState, saving: false}))
  //           alert('Error: could not add. Contact and admin.')
  //           catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addIssueAction', JSON.stringify(error), props.user.username, props.user.device)

  //         }
  //       )

  //     })

  //   }

  //   if (props.user.issue < 2) {
  //     alert('You do not have the required permission. Contact an admin.')
  //   } else if (isValidatedAction.actionDate === null || isValidatedAction.actionDate ==='') {
  //       alert("Please select an action date.")
  //   } else if (isValidatedAction.description === null || isValidatedAction.description ==='') {
  //     alert("Please select a description.")
  //   } else {

  //     addToServer()

  //   }

  // }

  // const editAction = () => {

  //   const addToServer = () => {

  //     setIsModal(prevState => ({...prevState, saving: true}))

  //     getLocation(function(latlng){

  //       fetch('/api/editIssueAction', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,            
  //           device: props.user.device,
  //           id: isValidatedAction.id,
  //           actionDate: isValidatedAction.actionDate,            
  //           description: replaceStr(isValidatedAction.description)
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))

  //           setFetchedAction(fetchedAction.map(data =>
  //             data.id === isValidatedAction.id ?
  //             {...data,
  //               modby: props.user.username,
  //               modtime: formatDateTime(new Date()),
  //               modlat: latlng.lat,
  //               modlng: latlng.lng,
  //               moddevice: props.user.device,
  //               actionDate: isValidatedAction.actionDate,                
  //               description: isValidatedAction.description
  //             } :
  //             data
  //           ))            

  //           addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, `edit issue action`, `issue id: ${isValidated.id}: ${isValidatedAction.description}`, props.user.username)

  //           isChangedAction.current = false
  //           closeAction()            

  //         },
  //         (error) => {
  //           setIsModal(prevState => ({...prevState, saving: false}))
  //           alert('Error: could not edit. Contact and admin.')
  //           catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editIssueAction', JSON.stringify(error), props.user.username, props.user.device)

  //         }
  //       )

  //     })

  //   }
    
  //   if (isModal.warning > 1) {
  //     alert('You do not have the required permission. Contact an admin.')
  //   } else if (isChangedAction.current === false) {
  //     alert('Nothing has been changed.')      
  //   } else if (isValidatedAction.actionDate === null || isValidatedAction.actionDate ==='') {
  //       alert("Please select an action date.")
  //   } else if (isValidatedAction.description === null || isValidatedAction.description ==='') {
  //     alert("Please select a description.")
  //   } else {

  //     addToServer()

  //   }

  // }

  // const deleteAction = () => {

  //   const addToServer = () => {

  //     setIsModal(prevState => ({...prevState, saving: true}))

  //     fetch('/api/deleteIssueAction', {
  //       method: 'post',
  //       headers: {
  //         'Accept': 'application/json, text/plain, */*',
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         id: isValidatedAction.id
  //       })
  //     })
  //     .then(res=>res.json())
  //     .then(
  //       (result) => {
  //         //console.log('result: ' + JSON.stringify(result))          

  //         addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete issue action', `issue id: ${isValidated.id}: ${isValidatedAction.description}`, props.user.username)

  //         //fetchData()
  //         setFetchedAction(fetchedAction.filter(data => data.id !== isValidatedAction.id))
  //         isChangedAction.current = false
  //         closeModal()

  //       },
  //       (error) => {
  //         setIsModal(prevState => ({...prevState, saving: false}))
  //         alert('Error: could not delete. Contact and admin.')
  //         catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteIssueAction', JSON.stringify(error), props.user.username, props.user.device)

  //       }
  //     )

  //   }

  //   if (isModal.warning > 0) {
  //       alert('You do not have the required permission. Contact an admin.')
  //   } else {

  //     if (window.confirm('If you proceed, this will be deleted. Proceed?')) addToServer()        

  //   }

  // } 

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    // alert('Use the preset in the Drawing tool under Plans')
    // return

    if (props.user.issue < 2) {

      alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      // let maxIssueNo = Math.max(...fetchedData.map(data => data.issueNo))

      // setIsValidated(prevState => ({...prevState, issueNo: fetchedData.length === 0 ? 1 : maxIssueNo + 1}))
      setIsModal(prevState => ({...prevState, add: true}))

    }

  }

  const openEdit = (i) => setIsModal(prevState => ({...prevState, edit: true, i: i}))  

  const toggleUnresolved = () => setIsModal(prevState => ({...prevState, unresolved: isModal.unresolved ? false : true}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  const openAction = (i, id) => {

    let actions = fetchedAction.map((data, j) => {
      
      if (id === data.issueId) {

        return (
          <>
            <tr key={data.id.toString()} data-table='action' data-i={i} data-j={j} data-id={data.id} onClick={selectRow}>            
              <td style={{textAlign: 'left'}}>{data.description} - <span style={{color: 'dodgerblue'}}>{data.entryby}</span> <span style={{color: 'gray'}}>{data.actionDate}</span></td>          
            </tr>                
          </>
        )

      }
  
    })

    listOfActions.current = (
      <table>

        <thead>
          <tr>            
            <th style={{textAlign: 'left'}}>Actions</th>
          </tr>
        </thead>

        <tbody>
          {actions}
        </tbody>

      </table>
    )
    
    setIsModal(prevState => ({...prevState, action: true}))

  }

  const openActionAdd = () => setIsModal(prevState => ({...prevState, addAction: true}))

  const openActionEdit = (i) => setIsModal(prevState => ({...prevState, editAction: true, i: i}))

  const closeModal = () => setIsModal(prevState => ({...prevState, add: false, edit: false, saving: false}))

  const closeAction = () => {

    setIsModal(prevState => ({...prevState, action: false, addAction: false, editAction: false, saving: false, i: null}))

    listOfActions.current = null     

  }
  
  const updateFetchedData = (data) => setFetchedData(data)

  const updateFetchedAction = (data) => setFetchedAction(data)

  let listOfData = fetchedData.map((data, i) => {    

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    // <td>{data.issueDate}</td>
    // <td>{data.entryby}</td>
    // <td>{data.description}</td>

    if ((isModal.unresolved && data.resolved == 0 && filter) || !isModal.unresolved && filter) {

      // let actionList = null

      // for (let i = 0; i < fetchedAction.length; i++) {
      //   if (fetchedAction[i].issueId === data.id && i === 0) {
      //     actionList = <li style={{marginBottom: 10}}>{fetchedAction[0].description} - <span style={{color: 'dodgerblue'}}>{fetchedAction[0].entryby}</span> <span style={{color: 'gray'}}>{fetchedAction[0].actionDate}</span></li>
      //   } else if (fetchedAction[i].issueId === data.id) {
      //     actionList += <li>{fetchedAction[0].description} - <span style={{color: 'dodgerblue'}}>{fetchedAction[0].entryby}</span> <span style={{color: 'gray'}}>{fetchedAction[0].actionDate}</span></li>
      //   }

      //   console.log(`list: ${JSON.stringify(actionList)}`)
      // }

      let count = 0

      let actionList = fetchedAction.map((actionData, j) => {

        if (actionData.issueId === data.id && j === 0) {
          count += 1
          return ( 
            <li style={{marginBottom: 10}}>{actionData.description} - <span style={{color: 'dodgerblue'}}>{actionData.entryby}</span> <span style={{color: 'gray'}}>{actionData.actionDate}</span></li>
          )
        } else if (actionData.issueId === data.id) {
          count += 1
          return ( 
            <li>{actionData.description} - <span style={{color: 'dodgerblue'}}>{actionData.entryby}</span> <span style={{color: 'gray'}}>{actionData.actionDate}</span></li>
          )
        }

      })

      //console.log(`list: ${list}`)

      return (
        <>
          <tr key={data.id.toString()} data-table='issue' data-i={i} data-id={data.id} onClick={selectRow}>
          <td>{data.resolved == 0 ? <Icon name='error_outline' color='tomato' /> : data.resolved == 2 ? <Icon name='sentiment_dissatisfied' color='orange' /> : <Icon name='task_alt' />}</td>
            <td>{data.issueNo}</td>
            <td style={{textAlign: 'left'}}>{data.description} - <span style={{color: 'dodgerblue'}}>{data.entryby}</span> <span style={{color: 'gray'}}>{data.issueDate}</span></td>          
          </tr>
          {count > 0 ?
            <tr data-table='listOfActions' data-i={i} data-id={data.id} onClick={selectRow}>
              <td style={{textAlign: 'left', fontSize: 14}} colSpan="3">
                <ul>
                  {actionList}
                </ul>                
              </td>
            </tr> :
            null
          }      
        </>
      )

    }

  })
  
  let content = (
    <>
      {props.filter.jobNumber === null || props.filter.jobNumber === '' ?
        null :
        <>
          {isModal.action ? <Modal content={listOfActions.current} closeModal={closeAction} /> : null}
          {isModal.add || isModal.edit || isModal.addAction || isModal.editAction ?
            <IssueInput                
              close={closeModal}
              closeAction={closeAction}
              fetchedData={fetchedData}
              fetchedAction={fetchedAction}
              updateFetchedData={updateFetchedData}
              updateFetchedAction={updateFetchedAction}
              openActionAdd={openActionAdd}           
              isModal={isModal}
              user={props.user}
              filter={props.filter}
              planMode={props.planMode}
              component={props.component}
            /> : null
          }
          {!isModal.loading ?
            <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

              <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

                <div style={{width: '100%'}}>

                  {props.user.issue < 2 ? null : props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
                  <Icon name='error_outline' color={isModal.unresolved ? 'tomato' : 'gray'} title='View unresolved issues' onClick={toggleUnresolved} />                  
                  <Icon name='refresh' title='Refresh' onClick={fetchData} />

                </div>

                <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

                {fetchedData.length > 0 ?

                  <>

                    <small style={{margin: 10}}>{listOfData.length} Record{listOfData.length === 1 ? '' : 's'}</small>

                    <div style={{flex: '1', overflow: 'auto'}}>

                      <table>

                        <thead>
                          <tr>
                            <th></th>
                            <th>#</th>
                            <th style={{textAlign: 'left'}}>Issue</th>
                          </tr>
                        </thead>

                        <tbody>
                          {listOfData}
                        </tbody>

                      </table>

                    </div>

                  </> : <p style={{margin: 10}}>No issues found.</p>

                }

              </div>

            </div> :
            <p style={{margin: 10}}>Loading...</p>

          }

        </>
      }
    </>
  )

  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content  

}

export default Issue
