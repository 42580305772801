import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Warning from 'components/utils/Warning'
//import Mileage from 'components/main/Mileage'
import Stats from 'components/manage/Stats'

import { formatDateTime, formatDateYMDHM, formatTime, filterData } from 'scripts/common'

const Home = (props) => {

  const canvasRef = useRef(null)
  const chartRef = useRef(null)

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const mapPosition = useRef({
    center: null,
    zoom: null
  })

  const [fetchedData, setFetchedData] = useState([])
  const [fetchedDistinctUsers, setFetchedDistinctUsers] = useState([])

  const [searchValue, setSearchValue] = useState('')

  const [isModal, setIsModal] = useState({
    table: true,
    chart: true,
    google: false,
    warning: true,
    activity: true,
    last: false
  })

  async function fetchData(who) {

    let activity = isModal.activity
    let last = isModal.last

    if (who === 'activity') {
      activity = isModal.activity ? false : true
    } else if (who === 'last') {
      last = isModal.last ? false : true
    }

    let response = await fetch('/api/selectDistinctUsersForChart', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        user: props.user,
        activity: activity,
        last: last
      })
    })
    .then(res=>res.json())
    .then(
      (user) => {

        let users = user.map(data => data.entryby.toLowerCase().trim())

        setFetchedDistinctUsers(users)

        fetch('/api/selectActivityForChart', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: props.filter,
            user: props.user,
            activity: activity,
            last: last
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            //console.log(`users: ${JSON.stringify(users)}`)

            setFetchedData(result.map((data, i) => {

              // for schedule
              
              let duration = Number(data.additionalFilterCriteria)
              let date = new Date(data.time);
              date.setHours(date.getHours() + duration);

              //console.log(`who: ${data.who} -- > ${users.indexOf(data.who.toLowerCase())}`)

              return {...data,
                time: formatDateTime(data.time),
                timeEnd: formatDateTime(date),
                yLabelKey: users.indexOf(data.who.toLowerCase().trim())
              }

            }))
          },
          (error) => {
            console.log('Error: selectActivityForChart --> ' + error)
          }
        )

      },
      (error) => {
        console.log('Error: selectActivityForChart --> ' + error)
      }
    )

  }

  const buildChart = () => {

    let canvas, ctx, i

    let style = {
      borderWidth: 1,
      pointBorderColor: 'black',
      pointRadius: 7,
      pointHoverRadius: 10,
      fill: false,
      tension: 0,
      showLine: false,
    }

    let styleOther = {
      borderWidth: 1,
      pointBorderColor: 'black',
      pointRadius: 4,
      pointHoverRadius: 7,
      fill: false,
      tension: 0,
      showLine: false,
    }

    // let img = new Image()
    // img.src ='icons/lab.png';

    let arrNukeOut = {
      ...style,
      label: 'Nuke Out',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'MediumSeaGreen',
      pointStyle: 'triangle'
    }

    let arrNukeIn = {
      ...style,
      label: 'Nuke In',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'tomato',
      pointStyle: 'triangle'
    }

    let arrStatus = {
      ...style,
      label: 'status',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'tomato',
      pointStyle: 'circle'
    }

    let arrStatusStart = {
      ...style,
      label: 'Start Day',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'MediumSeaGreen',
      pointStyle: 'circle'
    }

    let arrStatusArrive = {
      ...style,
      label: 'Arrive Job',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'DodgerBlue',
      pointStyle: 'circle'
    }

    let arrStatusBreak = {
      ...style,
      label: 'Break',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: '#ae7e4e',
      pointStyle: 'circle'
    }

    let arrStatusDepart = {
      ...style,
      label: 'Depart Job',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'orange',
      pointStyle: 'circle'
    }

    let arrStatusEnd = {
      ...style,
      label: 'End Day',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'tomato',
      pointStyle: 'circle'
    }

    let arrTest = {
      ...style,
      label: 'test',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'black',
      pointStyle: 'triangle'
    }

    let arrLab = {
      ...style,
      label: 'lab',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'brown',
      pointStyle: 'rectRot'
    }

    let arrDrawing = {
      ...style,
      label: 'drawing',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'violet',
      pointStyle: 'rectRounded'
    }

    let arrDailyInc = {
      ...style,
      label: 'Incomplete',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'red',
      pointStyle: 'rect'
    }

    let arrDailyCom = {
      ...style,
      label: 'Complete',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'yellow',
      pointStyle: 'rect'
    }

    let arrDailySign = {
      ...style,
      label: 'Signed',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'green',
      pointStyle: 'rect'
    }

    let arrMaterial = {
      ...style,
      label: 'Material',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'lightgray',
      pointStyle: 'circle'
    }

    let arrOther = {
      ...styleOther,
      label: 'Activity',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'white',
      pointStyle: 'circle'
    }
    
    // let styleSchedule = {
    //   borderWidth: 1,
    //   pointBorderColor: 'black',
    //   pointRadius: 7,
    //   pointHoverRadius: 10,
    //   fill: false,
    //   tension: 0,
    //   showLine: true,
    // }

    let arrSchedule = {
      borderWidth: 1,
      pointBorderColor: 'black',
      pointRadius: 10,
      pointHoverRadius: 12,
      fill: false,
      tension: 0,
      showLine: true,
      label: 'Schedule',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'white',
      pointStyle: 'circle'
    }

    let chartData = []
    
    for (i = 0; i < fetchedData.length; i++) {

      if (filterData(fetchedData[i], searchValue)) {

        if (fetchedData[i].tbl === 'schedule') {
          
          let color = fetchedData[i].jobnumber === '' ? 'lightgreen' : 'gold'          
          
          chartData.push(
            {
              borderWidth: 5,
              pointBorderColor: 'black',
              pointBorderWidth: 1,
              pointRadius: 5,
              pointHoverRadius: 10,
              fill: false,
              tension: 0,
              showLine: true,
              label: fetchedData[i].jobnumber === '' ? '' : fetchedData[i].jobnumber,
              data: [
                { x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by, tbl: fetchedData[i].tbl},
                { x: fetchedData[i].timeEnd, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by, tbl: fetchedData[i].tbl}
              ],
              borderColor: color,
              pointBackgroundColor: color,
              pointStyle: 'circle'
            }
          )
        
        } else if (fetchedData[i].tbl === 'nukeOut') {
          arrNukeOut.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: '', phase: '', description: fetchedData[i].description, who: fetchedData[i].by, tbl: fetchedData[i].tbl})
        } else if (fetchedData[i].tbl === 'nukeIn') {
          arrNukeIn.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: '', phase: '', description: fetchedData[i].description, who: fetchedData[i].by, tbl: fetchedData[i].tbl})
        } else if (fetchedData[i].tbl === 'status') {

          switch (fetchedData[i].description) {
            case "Start Day":
                arrStatusStart.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
                break;
            case "Arrive Job":
                arrStatusArrive.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
                break;
            case "On Break":
                arrStatusBreak.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
                break;
            case "Depart Job":
                arrStatusDepart.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
                break;
            case "End Day":
                arrStatusEnd.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
                break;
            default:
                console.log('nothing')
          }

        } else if (fetchedData[i].tbl === 'test') {
          arrTest.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
        } else if (fetchedData[i].tbl === 'lab') {
          arrLab.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
        } else if (fetchedData[i].tbl === 'drawing') {
          arrDrawing.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
        } else if (fetchedData[i].tbl === 'material') {
          arrMaterial.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
        } else if (fetchedData[i].tbl === 'daily') {

          switch (fetchedData[i].description) {
            case "Incomplete":
                arrDailyInc.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
                break;
            case "Complete":
                arrDailyCom.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
                break;
            case "Signed":
                arrDailySign.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
                break;
            default:
                console.log('nothing')
          }

        } else {
          if (isModal.activity) arrOther.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobnumber, phase: fetchedData[i].phase, description: fetchedData[i].description, who: fetchedData[i].by})
        }

      }

    }

    chartData = props.user.userlevel === 'admin' ?
    [arrNukeOut, arrNukeIn, arrStatusStart, arrStatusArrive, arrStatusBreak, arrStatusDepart, arrStatusEnd, arrTest, arrLab, arrDrawing, arrMaterial, arrDailyInc, arrDailyCom, arrDailySign, arrOther, ...chartData] :
    [arrNukeOut, arrNukeIn, arrStatusStart, arrStatusArrive, arrStatusBreak, arrStatusDepart, arrStatusEnd, arrTest, arrLab, arrDrawing, arrMaterial, arrDailyInc, arrDailyCom, arrDailySign, ...chartData]

    let minTime = Math.min(...fetchedData.map(data => data.entrytime))

    //console.log(`chartData: ${JSON.stringify(chartData)}`)

    canvas = canvasRef.current
    ctx = canvas.getContext('2d')

    let userColors = ['dodgerblue', 'mediumseagreen', 'orange']

    if (chartRef.current) chartRef.current.destroy()

    chartRef.current = new window.Chart(ctx, {
      // The type of chart we want to create
      type: 'line',

      // The data for our dataset
      data: {
        datasets: chartData
      },
      options: {
        animation: 0,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'hour',
              unitStepSize: 1,
              displayFormats: {
                'hour': dateDiff() ? 'M/DD h A' : 'h A'
              }
              //min: minTime
              //min: 'Wednesday, Oct 16, 2019, 5:00 AM'
            },
            scaleLabel: {
              display: true
            },
            ticks: {
              beginAtZero: true,
              min: minTime              
            }
           }],
           yAxes: [{
               ticks: {
                 beginAtZero: true,
                 callback: function(value, index, values) {
                   return fetchedDistinctUsers[value];
                 },
                 fontColor: (value) => {
                  //console.log(`color: ${userColors[fetchedDistinctUsers[value].type]}`)
                  return userColors[fetchedDistinctUsers[value].type]
                },
                 stepSize: 1
               }
           }]
        },
        legend: {
          labels: {
              usePointStyle: true,
          },
          //fullWidth: true,
          //display: true,
          // labels: {
          //   filter: function(dataItem, data) {
          //     let tbl = data.datasets[dataItem.datasetIndex]//.data[dataItem.index]//.tbl
          //     console.log(`tbl: ${JSON.stringify(tbl)}`)
          //     if (tbl === 'schedule') return tbl
              
          //   },
          // }
        },
        hover: {
            mode: 'nearest',
            intersect: true
        },
        tooltips: {
          //yAlign: 'bottom',
          mode: 'nearest',
          callbacks: {
            label: function(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].who
            },
            afterLabel: function(tooltipItem, data) {

              let tbl = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].tbl

              //console.log(`tbl ${JSON.stringify(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`)

              if (tbl === 'nukeOut' || tbl === 'nukeIn') {
                return `
                  Nuke #${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].description} ${tbl === 'nukeOut' ? 'Check out' : 'Checked in'}
                `
              } else {
                return `
                  JN: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].jn}
                  Phase: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].phase}
                  ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].description}
                `
              }

            }
          },
          borderWidth: 2,
          borderColor: '#D3D3D3',
          backgroundColor: '#FFFFFF',
          titleFontSize: 16,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000000',
          bodyFontSize: 20,
          displayColors: false
        }

      }

    });

  }

  const dateDiff = () => {
    let startDate = new Date(props.filter.startDate)
    let endDate = new Date(props.filter.endDate)

    let diff = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) // in days for convenience

    return diff > 0 ? true : false
  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  useEffect(() => {
    //if (isModal.chart && !dateDiff()) buildChart()

    if (isModal.chart) buildChart()
  })

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
      .then(result => {
        if (isModal.chart) buildChart()

        if (isModal.chart && !dateDiff()) buildChart()
        //if (isModal.google) addMarkers()
      })
    }, 15000) // time in milliseconds (ie 1 mins)
    return () => clearInterval(interval);
  }, [isModal, props.filter, searchValue, fetchedData])

  //============== GOOGLE MAP ======================

  useEffect(() => {
    if (isModal.google) initMap()
  }, [isModal.google])

  useEffect(() => {
    if (isModal.google) addMarkers()
  }, [isModal.google, props.filter, searchValue, fetchedData])

  // useEffect(() => {
  //   if (isModal.google) updateMap()
  // }, [searchValue])

  // Sets the map on all markers in the array.
  const highlightMarker = (e) => {

    let id = parseInt(e.target.parentNode.getAttribute('data-id'))
    let imageName, tbl
    let imagePath = 'http://labs.google.com/ridefinder/images/'

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        // markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
        markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)

        let icon = {
            url: `http://maps.google.com/mapfiles/ms/icons/yellow.png`,
            scaledSize: new window.google.maps.Size(40, 40), // scaled size
            labelOrigin: new window.google.maps.Point(19, 12)
        }

        markerRef.current[i].setIcon(icon)

      } else {

        // tbl = markerRef.current[i].tbl

        // imageName =
        // tbl === 'nukeIn' ? 'mm_20_green.png' :
        // tbl === 'nukeOut' ? 'mm_20_red.png' :
        // tbl === 'status' ? 'mm_20_blue.png' :
        // tbl === 'test' ? 'mm_20_orange.png' :
        // tbl === 'lab' ? 'mm_20_brown.png' :
        // tbl === 'daily' ? 'mm_20_gray.png' :
        // tbl === 'drawing' ? 'mm_20_purple.png' : 'mm_20_white.png'

        // markerRef.current[i].setIcon(`${imagePath}${imageName}`)
        markerRef.current[i].setAnimation(null)
        

        let icon = {
          url: `http://maps.google.com/mapfiles/ms/icons/red.png`,
          scaledSize: new window.google.maps.Size(40, 40), // scaled size
          labelOrigin: new window.google.maps.Point(19, 12)
      }

      markerRef.current[i].setIcon(icon)
        
      }

      //markerRef.current[i].setMap(mapRef.current);
    }
  }  

  const initMap = () => {

    mapRef.current = new window.google.maps.Map(googleRef.current, {
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      zoom: 9,
      center: {lat: 33.847827, lng: -117.862286}
    });

    //console.log('chart?')

    addMarkers()

  }

  const addMarkers = () => {

    let data = fetchedData.filter(data => {

      if (data.lat !== '' && data.lat !== null && data.lng !== '' && data.lng !== null) {

          let jn = data.jobnumber === null ? '' : data.jobnumber
          let gd = data.phase === null ? '' : data.phase === 'RG' ? 'Grading' : data.phase === 'PG' ? 'Post' : data.phase
          let description = data.description === null ? '' : data.description
          let tbl = data.tbl === null ? '' : data.tbl
          let by = data.who === null ? '' : data.who
          let time = data.time === null ? '' : data.time        

        return data

      }


    })

    let infoWindow = new window.google.maps.InfoWindow;

    let marker, i, latLng, visible, imageName
    let imagePath = 'http://labs.google.com/ridefinder/images/'
    let jn, gd, description, tbl, by, time

    //let i, latLng

    let bounds = new window.google.maps.LatLngBounds()

    // clear markers

    for (i = 0; i < markerRef.current.length; i++) {
      markerRef.current[i].setMap(null);
    }

    // add markers

    for (i = 0; i < data.length; i++) {

      jn = data[i].jobnumber === null ? '' : data[i].jobnumber
      gd = data[i].phase === null ? '' : data[i].phase === 'RG' ? 'Grading' : data[i].phase === 'PG' ? 'Post' : data[i].phase
      description = data[i].description === null ? '' : data[i].description
      tbl = data[i].tbl === null ? '' : data[i].tbl
      by = data[i].who === null ? '' : data[i].who
      time = data[i].time === null ? '' : data[i].time
      visible = filterData(data[i], searchValue)

      //console.log(`${visible}`)

      imageName =
      tbl === 'nukeIn' ? 'mm_20_green.png' :
      tbl === 'nukeOut' ? 'mm_20_red.png' :
      tbl === 'status' ? 'mm_20_blue.png' :
      tbl === 'test' ? 'mm_20_orange.png' :
      tbl === 'lab' ? 'mm_20_brown.png' :
      tbl === 'daily' ? 'mm_20_gray.png' :
      tbl === 'drawing' ? 'mm_20_purple.png' : 'mm_20_white.png'

      let icon = {
          url: `http://maps.google.com/mapfiles/ms/icons/red.png`,
          scaledSize: new window.google.maps.Size(40, 40), // scaled size
          labelOrigin: new window.google.maps.Point(19, 12)
      }

      // icon: {
      //   url: `${imagePath}${imageName}`
      // },

      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].lat, data[i].lng),
        jn: jn,
        gd: gd,
        description: description,
        tbl: tbl,
        by: by,
        time: time,
        map: mapRef.current,        
        icon: icon,
        label: { color: 'black', fontWeight: 'bold', fontSize: '10px', text: `${by.substring(0, 2).toUpperCase()}` },
        id: data[i].id,
        visible: visible
      });

      marker.setMap(mapRef.current)

      markerRef.current.push(marker)

      latLng = new window.google.maps.LatLng(data[i].lat, data[i].lng);

      bounds.extend(latLng);

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.setContent(`
          <div>
            <p>${this.time}</p>
            <p>${this.by}</p>
            <p>${this.jn} ${this.gd}</p>
            <p>${this.tbl}: ${this.description}</p>
          </div>
          `);
        infoWindow.open(mapRef.current, this);
      });

    }

    //mapRef.current.fitBounds(bounds)

    //mapRef.current.setCenter(bounds.getCenter())

    // if (mapPosition.current.center === null || mapPosition.current.zoom === null) {
    //
    //   mapRef.current.fitBounds(bounds)
    //
    //   mapRef.current.setCenter(bounds.getCenter())
    //

    //
    //   mapPosition.current = {
    //     center: mapRef.current.getCenter(),
    //     zoom: mapRef.current.getZoom()
    //   }
    //
    // } else {
    //
    //   mapRef.current.setCenter(mapPosition.current.center)
    //
    //   mapRef.current.setZoom(mapPosition.current.zoom)
    //
    // }

  }

  const toggleTable = () => setIsModal(prevState => ({...prevState, table: isModal.table ? false : true}))

  const toggleWarning = () => setIsModal(prevState => ({...prevState, warning: isModal.warning ? false : true}))

  const toggleActivity = () => {
    fetchData('activity')
    setIsModal(prevState => ({...prevState, activity: isModal.activity ? false : true}))
  }

  const toggleLast = () => {
    fetchData('last')
    setIsModal(prevState => ({...prevState, last: isModal.last ? false : true}))
  }

  const toggleChart = () => setIsModal(prevState => ({...prevState, chart: isModal.chart ? false : true}))

  const toggleGoogle = () => {

    if (isModal.google) mapPosition.current = {center: null, zoom: null}

    setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }  

  const viewDaily = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {
      window.open(`dailies/daily${fetchedData[i].id}.pdf`)
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.phase === null ? '' : data.phase === 'RG' ? 'Grading' : data.phase === 'PG' ? 'Post' : data.phase
    let description = data.description === null ? '' : data.description
    let tbl = data.tbl === null ? '' : data.tbl
    let by = data.who === null ? '' : data.who
    let time = data.time === null ? '' : dateDiff() ? formatDateYMDHM(data.time) : formatTime(data.time)
    let device = data.device === null ? '' : data.device

    if (filterData(data, searchValue)) {      

      return (

        <tr data-id={data.id} onClick={tbl === 'daily' ? viewDaily : null} onMouseOver={highlightMarker}>
          <td style={{display: 'none'}}>{i}</td>
          <td>
            {data.lat !== null && data.lat !== '' ?
              <Icon name='location_on' /> :
              <Icon name='wrong_location' color='tomato' />
            }
          </td>
          <td>
            <Icon
              name={device === 'tablet' ? 'tablet_android' : device === 'mobile' ? 'phone_iphone' : device === 'desktop' ? 'computer' : 'device_unknown'}
              color={device === 'tablet' ? 'dodgerblue' : device === 'mobile' ? 'gray' : device === 'desktop' ? 'orange' : 'tomato'}
              title={device}
            />
          </td>
          <td>{time}</td>
          <td>{by}</td>
          <td>{jn} {gd}</td>
          <td>{tbl}</td>
          <td className='wrapText'>{description}</td>
        </tr>

      )

    }

  })

  let chartAndGoogle = (
    <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

      <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

        {isModal.chart ?

          <div style={{margin: 10, flex: '1', overflow: 'auto'}}>
            <canvas ref={canvasRef}></canvas>
          </div> : null

        }

        {isModal.google ?

          <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
            <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
          </div> : null

        }

      </div>

    </div>
  )

  //{dateDiff() ?

    //<p style={{margin: 10}}>Please select a single Date to view activity</p> :

    // {props.user.userlevel === 'admin' ? <Icon name='search' title='Toggle activity' color={isModal.activity ? 'mediumseagreen' : 'gray'} onClick={toggleActivity} outline={true} /> : null}
    // <Icon name='saved_search' title='Toggle Last Activity' color={isModal.last ? 'gold' : 'gray'} onClick={toggleLast} />

  return (

    <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

        <>

          <div style={{margin: 10, flex: isModal.table && (isModal.chart || isModal.google) ? '0 1 auto' : '1 0 auto'}}>

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              <div>
                <Icon name='refresh' title='Refresh' onClick={fetchData} />
                <Icon name='table_rows' title='Toggle Table' outline={true} color={isModal.table ? 'dodgerblue' : 'gray'} onClick={toggleTable} />
                <Icon name='timeline' title='Toggle Chart' color={isModal.chart ? 'dodgerblue' : 'gray'} onClick={toggleChart} />
                <Icon name='location_on' title='Toggle Map' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />
                <Icon name='error_outline' title='Unresolved Items' color={isModal.warning ? 'tomato' : 'gray'} onClick={toggleWarning} />
                
              </div>

              <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

              {isModal.warning ? <Warning filter={props.filter} user={props.user} component={props.component} /> : null}

              {isModal.table ?

                <>

                  {fetchedData.length > 0 ?

                    <div style={{margin: 10, flex: '1', overflow: 'auto', resize: 'horizontal'}}>

                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th>Time</th>
                            <th>User</th>
                            <th>JN</th>
                            <th>Category</th>
                            <th>Desc.</th>
                          </tr>
                        </thead>

                        <tbody>
                          {listOfData}
                        </tbody>
                      </table>

                    </div> :
                    <p style={{margin: 10}}>No activity found</p>

                  }

                </> : null

              }

              {!isModal.table && (isModal.chart || isModal.google) ? chartAndGoogle : null}

            </div>

          </div>

          {isModal.table && (isModal.chart || isModal.google) ? chartAndGoogle : null}

        </>



    </div>

  )
}

export default Home
